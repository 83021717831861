<template>
  <router-link
    :to="to"
    class="button"
    :class="['form-btn', buttonClass, disabledBtn ? 'cancel-btn' : '']"
  >
    {{ buttonText }}
  </router-link>
</template>

<script>
export default {
  name: "RouterBtn",
  props: {
    buttonText: String,
    to: {
      type: [String, Object],
      required: true,
    },
    buttonClass: {
      type: String,
      default: "",
    },
    disabledBtn: Boolean,
  },
};
</script>

<style scoped>
.button {
  display: inline-block;
  color: #fff;
  background-color: rgb(58, 51, 182);
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem 0.85rem;
  text-decoration: none;
  cursor: pointer;

  margin: 0.5rem 0.5rem 0.5rem 0;
}
.button:hover {
  color: #fff;
  background-color: rgb(58, 51, 252);
}
.delete-btn {
  background-color: #fff;
  color: rgb(192, 147, 0);
  border: rgb(192, 147, 0) 1px solid;
}
.delete-btn:hover {
  background-color: #eee;
  color: rgb(192, 147, 0);
  border: rgb(192, 147, 0) 1px solid;
}
.cancel-btn {
  background-color: #fff;
  color: rgb(58, 51, 182);
  border: rgb(58, 51, 182) 1px solid;
}
.cancel-btn:hover {
  background-color: #eee;
  color: rgb(58, 51, 182);
  border: rgb(58, 51, 182) 1px solid;
}
</style>
