import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { auth } from "@/firebase";

let app;
auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App).use(router).use(store).mount("#app"); // add store here
  }
});
