<template>
  <div class="traceability-item">
    <h3>製品情報</h3>
    <table class="trace-table">
      <thead>
        <tr>
          <th>項目</th>
          <td>製品追跡情報</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>捕獲年月日</th>
          <td>
            {{
              formatDate(traceData.timeToFactory) || "データを取得できません"
            }}
          </td>
        </tr>
        <tr>
          <th>捕獲場所</th>
          <td>
            {{ traceData.captureLocation || "データを取得できません" }}
          </td>
        </tr>
        <tr>
          <th>捕獲方法</th>
          <td>{{ traceData.captureMethod || "データを取得できません" }}</td>
        </tr>
        <tr>
          <th>性別</th>
          <td>{{ traceData.sex || "データを取得できません" }}</td>
        </tr>
        <tr>
          <th>体重（成獣/幼獣）</th>
          <td>
            {{ traceData.weight || "データを取得できません" }} kg
            <span v-if="!traceData.weight || isNaN(traceData.weight)"
              >（成幼判定不能）</span
            >
            <span v-else-if="traceData.weight < 35">（幼獣）</span>
            <span v-else>（成獣）</span>
          </td>
        </tr>
        <tr>
          <th>解体年月日</th>
          <td>
            {{
              formatDate(traceData.dismantlingDate) || "データを取得できません"
            }}
          </td>
        </tr>
        <tr>
          <th>加工年月日</th>
          <td>
            {{
              formatDate(traceData.dismantlingDate) || "データを取得できません"
            }}
          </td>
        </tr>
        <tr>
          <th>捕獲者</th>
          <td>{{ traceData.hunterName || "データを取得できません" }}</td>
        </tr>
        <tr>
          <th>認証施設責任者</th>
          <td>{{ traceData.facilityManager || "データを取得できません" }}</td>
        </tr>
        <tr>
          <th>電話番号</th>
          <td>{{ traceData.phoneNumber || "データを取得できません" }}</td>
        </tr>
        <tr>
          <th>個体識別子</th>
          <td>{{ traceData.individualId || "データを取得できません" }}</td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="traceData.facilityName && traceData.websiteUrl"
      class="facility-info"
    >
      <h3>施設情報</h3>
      <a :href="traceData.websiteUrl" target="_blank" class="facility-card">
        <img :src="traceData.ogpImageUrl" alt="解体施設の画像" />
        <div>
          <h3>{{ traceData.facilityName }}</h3>
          <p>詳しくはこちらをクリック</p>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from "@/firebase.js";
export default {
  data() {
    return {
      traceData: {
        productName: "",
        receivingDate: "",
        huntingArea: "",
        captureMethod: "",
        sex: "",
        age: "",
        weight: "",
        dismantlingDate: "",
        processingDate: "",
        hunterName: "",
        individualId: "",
        productKey: "",
        facilityManager: "",
        phoneNumber: "",
        shippingTo: "",
        facilityName: "",
        websiteUrl: "",
        ogpImageUrl: "",
      },
      message: "",
    };
  },
  created() {
    this.getTraceData();
  },
  methods: {
    async getTraceData() {
      this.traceData.individualId = this.$route.params.individualId;
      this.traceData.productKey = this.$route.params.productKey;
      const docRef = doc(db, "trace", this.traceData.individualId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        this.traceData = { ...this.traceData, ...docSnap.data() };
        this.message = "個体情報を取得しました";
      } else {
        this.message = "個体情報を取得できませんでした。";
      }
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}年${month}月${day}日`;
    },
  },
};
</script>
<style scoped>
.trace-table {
  width: 100%;
  border-collapse: collapse;
}

.trace-table thead th,
.trace-table thead td {
  font-weight: 600;
  text-align: center;
  background: #ddf;
  padding: 0.5rem 0;
}
.trace-table tbody th,
.trace-table tbody td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #fafafa;
  font-weight: 400;
  text-align: center;
  padding: 0.5rem 0;
}
.trace-table tr:nth-child(even) {
  background: #eef;
}
.trace-table tr:hover {
  opacity: 0.8;
}
.facility-info {
  margin-top: 20px;
  cursor: pointer;
  background: #fafafa;
}
.facility-info:hover {
  opacity: 0.7;
}
.facility-card {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 3px 5px 3px #ddf;
}

.facility-card img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 20px;
}

.facility-card div {
  flex: 1;
}
</style>
