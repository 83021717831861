<template>
  <div id="app">
    <CommonSidebar v-if="showSidebar" :user-id="userId" />
    <div class="main">
      <h2>{{ pageTitle }}</h2>
      <!-- タイトルを表示 -->
      <div v-if="errorMessage" class="error-message">
        <span>{{ errorMessage }}</span
        ><span>{{ errorRecieved }}</span>
      </div>
      <router-view :user-id="userId" v-slot="{ Component }" mode="out-in">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import CommonSidebar from "@/molecules/CommonSidebar.vue";
import RouterBtn from "./atoms/RouterBtn.vue";
import { auth } from "@/firebase.js";

export default {
  name: "App",
  components: {
    CommonSidebar,
    RouterBtn,
  },
  data() {
    return {
      pageTitle: "ラクシカ",
      userId: "",
      showSidebar: true,
      errorMessage: "",
      errorRecieved: "",
    };
  },
  watch: {
    $route(to) {
      if (auth.currentUser) {
        this.userId = auth.currentUser.email;
      }
      if (to.meta.title) {
        this.pageTitle = to.meta.title; // タイトルを更新
      }
      if (to.meta.public) {
        this.showSidebar = !to.meta.public;
      }
    },
  },
};
</script>

<style>
body {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
}
#app {
  display: flex;
  flex-direction: column;
  overflow: hidden; /* 追加 */
}
.error-message {
  padding: 1rem;
  background-color: rgba(200, 200, 255, 0.4);
  border-radius: 2rem;
}
.error-message span {
  display: inline-block;
}
.main {
  width: 95%;
  padding: 3rem 2.5% 5rem;
  margin: 0rem;
  left: 0;
}
.common-sidebar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  border-top: 1px solid #dee2e6;
  z-index: 2; /* 追加 */
}
h1 {
  font-size: 1.3rem;
  margin: 0.5rem 0;
  font-weight: 600;
}
h2 {
  font-size: 1.3rem;
  margin: 0.5rem 0;
}
h3 {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}
h4 {
  font-size: 1.1rem;
  margin: 0.5rem 0;
}
h5 {
  font-size: 1.1rem;
  margin: 0.5rem 0;
}
a {
  text-decoration: none;
}
a:link,
a:visited,
a:hover,
a:active {
  color: #2c3e50;
}
@media (min-width: 768px) {
  #app {
    flex-direction: row;
    width: 100%;
  }
  .main {
    padding: 0;
    text-align: left;
    position: relative;
    left: 15%;
    width: 80%;
    margin: 1rem 2.5%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
.fade-enter {
  z-index: 2;
}
.fade-enter-from {
  opacity: 0;
  transform: translateX(-50px);
}
.fade-enter-active {
  transition: all 0.5s ease;
}
.fade-leave {
  z-index: -1;
}
.fade-leave-to {
  opacity: 0;
  transform: translateX(50px);
}
</style>
