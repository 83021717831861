import { auth, db } from "@/firebase.js";
import { getDoc, doc } from "firebase/firestore";
import store from "@/store";
import { createRouter, createWebHistory } from "vue-router";
const LoginFacility = () => import("../pagesSettings/LoginFacility.vue");
const LogoutFacility = () => import("../pagesSettings/LogoutFacility.vue");
const LandingPage = () => import("../pagesSettings/LandingPage.vue");
const UpgradePlan = () => import("@/pagesSettings/UpgradePlan.vue");
const IndividualManage = () => import("@/pagesYezosika/IndividualManage.vue");
const LucioleDeer = () => import("@/pagesLuciole/LucioleDeer.vue");
const SauvageDeer = () => import("@/pagesSauvage/SauvageDeer.vue");
const ManoirDeer = () => import("@/pagesManoir/ManoirDeer.vue");
const LucioleDaily = () => import("@/pagesLuciole/LucioleDaily.vue");
const SettingsManage = () => import("@/pagesSettings/SettingsManage.vue");
const AdminBackup = () => import("@/pagesSettings/AdminBackup.vue");
const AdminSetup = () => import("@/pagesSettings/AdminSetup.vue");
import TraceProduct from "@/views/TraceProduct.vue";

const routes = [
  {
    path: "/login",
    name: "LoginFacility",
    component: LoginFacility,
    meta: {
      title: "ログイン",
      allowedPlans: [
        "エントリー",
        "スタンダード",
        "ソバージュ",
        "マノワ",
        "アイコンズ",
        "アドミン",
      ],
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: LoginFacility,
    meta: {
      title: "会員登録",
      allowedPlans: [
        "エントリー",
        "スタンダード",
        "ソバージュ",
        "マノワ",
        "アイコンズ",
        "アドミン",
      ],
    },
  },
  {
    path: "/landing",
    name: "LandingPage",
    component: LandingPage,
    meta: { title: "ラクシカの紹介" },
  },
  {
    path: "/upgrade-plan",
    name: "UpgradePlan",
    component: UpgradePlan,
    meta: {
      title: "会員プラン",
      allowedPlans: [
        "エントリー",
        "スタンダード",
        "ソバージュ",
        "マノワ",
        "アイコンズ",
        "アドミン",
      ],
    },
  },
  {
    path: "/logout",
    name: "LogoutFacility",
    component: LogoutFacility,
    meta: {
      requiresAuth: true,
      title: "ログアウト",
      allowedPlans: [
        "エントリー",
        "スタンダード",
        "ソバージュ",
        "マノワ",
        "アイコンズ",
        "アドミン",
      ],
    },
  },
  {
    path: "/",
    redirect: "/setting",
  },
  {
    path: "/individual-list",
    name: "IndividualList",
    component: IndividualManage,
    meta: {
      requiresAuth: true,
      title: "搬入した個体の一覧",
      allowedPlans: [
        "エントリー",
        "スタンダード",
        "ソバージュ",
        "マノワ",
        "アイコンズ",
        "アドミン",
      ],
    },
  },
  {
    path: "/individual-add",
    name: "IndividualAdd",
    component: IndividualManage,
    meta: {
      requiresAuth: true,
      title: "個体を追加",
      allowedPlans: [
        "エントリー",
        "スタンダード",
        "ソバージュ",
        "マノワ",
        "アイコンズ",
        "アドミン",
      ],
    },
  },
  {
    path: "/individual-edit/:id",
    name: "IndividualEdit",
    component: IndividualManage,
    props: true,
    meta: {
      requiresAuth: true,
      title: "個体を編集",
      allowedPlans: [
        "エントリー",
        "スタンダード",
        "ソバージュ",
        "マノワ",
        "アイコンズ",
        "アドミン",
      ],
    },
  },
  {
    //以下、個社データのみのファイルを作成して繰り返し書き直さないようにする。
    path: "/luciole-deer-list",
    name: "LucioleDeerList",
    component: LucioleDeer,
    meta: {
      requiresAuth: true,
      title: "搬入した個体の一覧",
      allowedPlans: ["アイコンズ", "アドミン"],
    },
  },
  {
    path: "/luciole-deer-add",
    name: "LucioleDeerAdd",
    component: LucioleDeer,
    meta: {
      requiresAuth: true,
      title: "個体を追加",
      allowedPlans: ["アイコンズ", "アドミン"],
    },
  },
  {
    path: "/luciole-deer-edit/:id",
    name: "LucioleDeerEdit",
    component: LucioleDeer,
    props: true,
    meta: {
      requiresAuth: true,
      title: "個体を編集",
      allowedPlans: ["アイコンズ", "アドミン"],
    },
  },
  {
    //以下、個社データのみのファイルを作成して繰り返し書き直さないようにする。
    path: "/sauvage-deer-list",
    name: "SauvageDeerList",
    component: SauvageDeer,
    meta: {
      requiresAuth: true,
      title: "搬入した個体の一覧",
      allowedPlans: ["ソバージュ", "アドミン"],
    },
  },
  {
    path: "/sauvage-deer-add",
    name: "SauvageDeerAdd",
    component: SauvageDeer,
    meta: {
      requiresAuth: true,
      title: "個体を追加",
      allowedPlans: ["ソバージュ", "アドミン"],
    },
  },
  {
    path: "/sauvage-deer-edit/:id",
    name: "SauvageDeerEdit",
    component: SauvageDeer,
    props: true,
    meta: {
      requiresAuth: true,
      title: "個体を編集",
      allowedPlans: ["ソバージュ", "アドミン"],
    },
  },
  {
    //以下、個社データのみのファイルを作成して繰り返し書き直さないようにする。
    path: "/manoir-deer-list",
    name: "ManoirDeerList",
    component: ManoirDeer,
    meta: {
      requiresAuth: true,
      title: "搬入した個体の一覧",
      allowedPlans: ["マノワ", "アドミン"],
    },
  },
  {
    path: "/manoir-deer-add",
    name: "ManoirDeerAdd",
    component: ManoirDeer,
    meta: {
      requiresAuth: true,
      title: "個体を追加",
      allowedPlans: ["マノワ", "アドミン"],
    },
  },
  {
    path: "/manoir-deer-edit/:id",
    name: "ManoirDeerEdit",
    component: ManoirDeer,
    props: true,
    meta: {
      requiresAuth: true,
      title: "個体を編集",
      allowedPlans: ["マノワ", "アドミン"],
    },
  },
  {
    //以下、個社データのみのファイルを作成して繰り返し書き直さないようにする。
    path: "/luciole-daily-list",
    name: "LucioleDailyList",
    component: LucioleDaily,
    meta: {
      requiresAuth: true,
      title: "日報の一覧",
      allowedPlans: ["アイコンズ", "アドミン"],
    },
  },
  {
    path: "/luciole-daily-add",
    name: "LucioleDailyAdd",
    component: LucioleDaily,
    meta: {
      requiresAuth: true,
      title: "日報を追加",
      allowedPlans: ["アイコンズ", "アドミン"],
    },
  },
  {
    path: "/luciole-daily-edit/:id",
    name: "LucioleDailyEdit",
    component: LucioleDaily,
    props: true,
    meta: {
      requiresAuth: true,
      title: "日報を編集",
      allowedPlans: ["アイコンズ", "アドミン"],
    },
  },
  {
    path: "/setting",
    name: "SettingsList",
    component: SettingsManage,
    props: true,
    meta: {
      requiresAuth: true,
      title: "設定",
      allowedPlans: [
        "アイコンズ",
        "エントリー",
        "スタンダード",
        "ソバージュ",
        "マノワ",
        "アイコンズ",
        "アドミン",
      ],
    },
  },
  {
    path: "/capture-edit",
    name: "CaptureEdit",
    component: SettingsManage,
    meta: {
      requiresAuth: true,
      title: "ハンターの一覧",
      allowedPlans: [
        "エントリー",
        "スタンダード",
        "ソバージュ",
        "マノワ",
        "アイコンズ",
        "アドミン",
      ],
    },
  },
  {
    path: "/facility-manager-edit",
    name: "FacilityEdit",
    component: SettingsManage,
    props: true,
    meta: {
      requiresAuth: true,
      title: "施設情報を編集",
      allowedPlans: [
        "エントリー",
        "スタンダード",
        "ソバージュ",
        "マノワ",
        "アイコンズ",
        "アドミン",
      ],
    },
  },
  {
    path: "/capture-location-edit",
    name: "CaptureLocationEdit",
    component: SettingsManage,
    props: true,
    meta: {
      requiresAuth: true,
      title: "捕獲場所の編集",
      allowedPlans: [
        "エントリー",
        "スタンダード",
        "ソバージュ",
        "マノワ",
        "アイコンズ",
        "アドミン",
      ],
    },
  },
  {
    path: "/detail-settings",
    name: "DetailSettings",
    component: SettingsManage,
    meta: {
      requiresAuth: true,
      title: "使いやすさを設定",
      allowedPlans: [
        "エントリー",
        "スタンダード",
        "ソバージュ",
        "マノワ",
        "アイコンズ",
        "アドミン",
      ],
    },
  },
  {
    path: "/setting-individual",
    name: "SettingsIndividual",
    component: SettingsManage,
    meta: {
      requiresAuth: true,
      title: "設定",
      allowedPlans: [
        "エントリー",
        "スタンダード",
        "ソバージュ",
        "マノワ",
        "アイコンズ",
        "アドミン",
      ],
    },
  },
  {
    path: "/admin-backup",
    name: "AdminBackup",
    component: AdminBackup,
    meta: {
      requiresAuth: true,
      title: "バックアップ",
      allowedPlans: ["アドミン"],
    },
  },
  {
    path: "/admin-setup",
    name: "AdminSetup",
    component: AdminSetup,
    meta: {
      requiresAuth: true,
      title: "セットアップ",
      allowedPlans: ["アドミン"],
    },
  },
  {
    path: "/trace/:individualId/:productKey",
    name: "TraceProduct",
    component: TraceProduct,
    meta: {
      requiresAuth: false,
      title: "ジビエトレーサビリティ",
      public: true, // サイドバーを非表示にするためのメタフィールド
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

async function getUserPlan(userId) {
  const userRef = doc(db, "clients", userId);
  const userDoc = await getDoc(userRef);
  return userDoc.exists ? userDoc.data().plan : null;
}
async function getUserRoutes(userId) {
  const userRef = doc(db, "clients", userId);
  const routesDoc = await getDoc(userRef);
  if (routesDoc.exists()) {
    const userRoutes = {
      defaultRoute: routesDoc.data().defaultRoute,
      sidebarRoutes: routesDoc.data().sidebarRoutes,
      onboardRoute: routesDoc.data().onboardRoute,
    };
    return userRoutes;
  }
}

let initialized = false;

auth.onAuthStateChanged(async (user) => {
  const isAuthenticated = !!user;
  const userPlan = user ? await getUserPlan(user.email) : null;
  const userRoutes = user ? await getUserRoutes(user.email) : null;

  store.commit("setAuthStatus", isAuthenticated);
  store.commit("setUserPlan", userPlan);
  store.commit("setUserRoutes", userRoutes);

  if (!initialized) {
    router.beforeEach((to, from, next) => {
      const requiresAuth = to.matched.some(
        (record) => record.meta.requiresAuth
      );
      const allowedPlans = to.matched.some((record) => record.meta.allowedPlans)
        ? to.matched.find((record) => record.meta.allowedPlans).meta
            .allowedPlans
        : null;
      const isLoginPage = to.path === "/login";
      const isSignupPage = to.path === "/signup";

      if (requiresAuth) {
        //ページから認証が求められている場合
        if (store.state.isAuthenticated) {
          //ユーザーがログインしているか?
          if (
            //ユーザーが開こうとしているページはプランに適合していない場合
            allowedPlans &&
            (!store.state.userPlan ||
              !allowedPlans.includes(store.state.userPlan))
          ) {
            next(store.state.userRoutes.defaultRoute || "/upgrade-plan");
            return;
          } else {
            //プランに適合している場合はそのまま進め
            if (isLoginPage || isSignupPage) {
              //ログインしているユーザーが開く必要のないページの場合
              next("/");
              return;
            }
          }
        } else {
          //ユーザーがログインしていない場合

          next("/landing");
          return;
        }
        initialized = true;
      }
      {
        next();
        return;
      }
    });
  }
});
export default router;
