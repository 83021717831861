// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth"; // ここを修正

const firebaseConfig = {
  apiKey: "AIzaSyA192PSUGuva_eqAi2DdKEfkOPylfVIlQo",
  authDomain: "deer-4-safe.firebaseapp.com",
  projectId: "deer-4-safe",
  storageBucket: "deer-4-safe.appspot.com",
  messagingSenderId: "968638519040",
  appId: "1:968638519040:web:7a6bc2f8120f41f37905e5",
  measurementId: "G-MZG8VFRC46",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app); // Add this line
export const storage = getStorage(app);
export const signInWithEmail = signInWithEmailAndPassword;
export const signUpWithEmail = createUserWithEmailAndPassword;
