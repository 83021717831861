import { createStore } from "vuex";
import {
  collection,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "@/firebase.js";

const store = createStore({
  state: {
    isAuthenticated: false,
    userPlan: null,
    userId: null,
    date: null,
    userRoutes: null,
    formTemplateKey: null,
    rawFormTemplate: null,
    filteredFormTemplate: null,
    formOptions: null,
    rawFormData: [],
    loadCollections: [],
    formData: [], // formのデータを保持するためのオブジェクト
    formTab: null,
    filteredFields: [], // フィルタリングされたフォームフィールド
    formVuex: true,
    formDocId: null,
    formQueryFirebase: null,
    formBeforeSubmit: {},
    listData: [], // listのデータを保持するための配列
    listVuex: true,
    listRef: {},
    listSelectedData: [], //pdfｙcsvのためにチェックされたアイテムのリスト
    listIsLoading: false,
    listQueryFirebase: null, // for queryFirebase
    listFilterData: undefined, // for filterData
    listSortBy: null,
    listSortOrder: null,
    listDisplayMapping: {},
    listException: "",
    listLastKey: null,
    listLoadMsg: null,
    listKey: null,
    selectedDocId: null,
    checkedItems: [],
    checkCard: false,
  },
  mutations: {
    setAuthStatus(state, status) {
      state.isAuthenticated = status;
    },
    setUserPlan(state, plan) {
      state.userPlan = plan;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setUserRoutes(state, userRoutes) {
      state.userRoutes = userRoutes;
    },
    setDate(state, date) {
      state.date = date;
    },
    setLoadCollections(state, list) {
      state.loadCollection = list;
    },
    setFormTemplateKey(state, key) {
      state.formTemplateKey = key;
    },
    setRawFormTemplate(state, template) {
      state.rawFormTemplate = template;
    },
    setFilteredFormTemplate(state, template) {
      state.filteredFormTemplate = template;
    },
    setRawFormData(state, data) {
      state.rawFormData = data;
    },
    setFormTab(state, current) {
      state.formTab = current;
    },
    // formデータに関するmutations
    setFormData(state, data) {
      state.formData = data;
    },
    updateFormField(state, fieldToUpdate) {
      const index = state.formData.findIndex(
        (f) => f.label === fieldToUpdate.label
      );
      if (index !== -1) {
        state.formData[index] = {
          ...state.formData[index],
          ...fieldToUpdate,
        };
      }
    },
    updateFormTemplate(state, fieldToUpdate) {
      const index = state.rawFormTemplate.findIndex(
        (f) => f.label === fieldToUpdate.label
      );
      if (index !== -1) {
        state.rawFormTemplate[index] = {
          ...state.rawFormTemplate[index],
          ...fieldToUpdate,
        };
      }
    },
    updateFilterData(state, payload) {
      const index = state.listFilterData.findIndex(
        (f) => f.label === payload.label
      );
      if (index !== -1) {
        state.listFilterData[index].options = payload.options;
        state.listFilterData[index].content =
          payload.content || state.listFilterData[index].content;
      }
    },

    clearFormData(state) {
      state.formData = [];
    },
    clearFormContent(state) {
      if (state.formData) {
        state.formData.forEach((item) => {
          item.content = "";
        });
      }
    },
    setFormQueryFirebase(state, query) {
      state.formQueryFirebase = query;
    },
    setFormDocId(state, id) {
      state.formDocId = id;
    },
    setFormBeforeSubmit(state, keys) {
      state.formBeforeSubmit = keys;
    },
    toggleFormVuex(state, val) {
      state.formVuex = val;
    },
    toggleListVuex(state, val) {
      state.listVuex = val;
    },
    setListQueryFirebase(state, query) {
      state.listQueryFirebase = query;
    },
    setListSelectedData(state, item) {
      state.listSelectedData.unshift(item);
    },
    clearListSelectedData(state) {
      state.listSelectedData = [];
    },
    // listデータに関するmutations
    setListData(state, list) {
      state.listData = list;
    },
    appendListData(state, newData) {
      // 既存のデータに新しいデータを追加
      state.listData = [...state.listData, ...newData];
    },
    clearListData(state) {
      state.listData = [];
    },
    setListLoadingStatus(state, status) {
      state.listIsLoading = status;
    },
    setListFilterData(state, filter) {
      state.listFilterData = filter;
    },
    setListSortBy(state, key) {
      state.listSortBy = key;
    },
    setListSortOrder(state, dirction) {
      state.listSortOrder = dirction;
    },
    setListKey(state, key) {
      state.listKey = key;
    },
    setListLastKey(state, num) {
      state.listLastKey = num;
    },
    setListLoadMsg(state, msg) {
      state.listLoadMsg = msg;
    },
    setListDisplayMapping(state, map) {
      state.listDisplayMapping = map;
    },
    setListException(state, key) {
      state.listException = key;
    },
    setCheckCard(state, boolean) {
      state.checkCard = boolean;
    },
    setSelectedDocId(state, id) {
      state.selectedDocId = id;
    },
    addCheckedItem(state, itemId) {
      state.checkedItems.push(itemId);
    },
    removeCheckedItem(state, itemId) {
      state.checkedItems = state.checkedItems.filter((id) => id !== itemId);
    },
    toggleCheckedItem(state, itemId) {
      if (state.checkedItems.includes(itemId)) {
        state.checkedItems = state.checkedItems.filter((id) => id !== itemId);
      } else {
        state.checkedItems.push(itemId);
      }
    },
    selectAllItems(state, allItems) {
      state.checkedItems = allItems.map((item) => item.id);
    },
    releaseAllItems(state) {
      state.checkedItems = [];
    },
  },
  actions: {
    async fetchFormTemplate({ commit, state, dispatch }) {
      console.log("1. FETCHING TEMPLATE", state.formTemplateKey);
      const docRef = doc(
        db,
        state.formQueryFirebase.collectionName,
        state.formQueryFirebase.userDoc
      );
      const docSnap = await getDoc(docRef);
      const formFields = docSnap.data()[state.formTemplateKey];
      commit("setRawFormTemplate", formFields);
      await dispatch("updateFormOptions");
    },
    async updateFormOptions({ state, commit }) {
      console.log("2. UPDATING OPTIONS");
      for (let obj of state.loadCollection) {
        let { subCollection, listName, listKey, labelKey } = obj;
        labelKey === undefined ? (labelKey = listKey) : labelKey;
        console.log("2-loop:  ", subCollection, listName, listKey, labelKey);
        try {
          if (subCollection === "/") {
            const docSnap = await getDoc(
              doc(
                db,
                state.formQueryFirebase.collectionName,
                state.formQueryFirebase.userDoc
              )
            );
            const data = docSnap.data()[listKey];
            console.log("UPDATE", subCollection);
            commit("updateFormTemplate", {
              label: labelKey || listName,
              options: [data],
            });
          } else {
            const collectionRef = collection(
              db,
              state.formQueryFirebase.collectionName,
              state.formQueryFirebase.userDoc,
              subCollection
            );
            const dataSnap = await getDocs(collectionRef);
            const data = dataSnap.docs.map((doc) => doc.data()[listKey]);
            console.log("UPDATE", subCollection);
            commit("updateFormTemplate", {
              label: labelKey || listName,
              options: data,
            });
          }
        } catch (error) {
          console.error(`Error getting ${subCollection} data: `, error);
        }
      }
    },
    async tabFilterFormTemplate({ state, commit }, tab) {
      let updatedTemplate = state.rawFormTemplate;
      console.log("3. tab filtering", tab, "in VUEX@tabFilterFormTemplate");
      let filteredTemplate;
      if (tab) {
        filteredTemplate = updatedTemplate.filter((item) => item.tab === tab);
      } else {
        filteredTemplate = updatedTemplate;
      }
      commit("setFilteredFormTemplate", filteredTemplate);
    },
    async fetchFormContent({ commit, state }, id) {
      if (!id) {
        console.log("4. ADD MODE @fetchFormContent");
        let formTemplate = state.filteredFormTemplate;
        commit("clearFormContent");
        commit("setFormData", formTemplate);
        return;
      } else {
        console.log("4. EDIT MODE", "@fetchFormContent");
        const docRef = doc(
          db,
          state.formQueryFirebase.collectionName,
          state.formQueryFirebase.userDoc,
          state.formQueryFirebase.subCollection,
          id
        ); // collectionRefメソッドの詳細は提供されていません
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          commit("setRawFormData", data);
          let formTemplate = state.filteredFormTemplate;
          formTemplate.forEach((field) => {
            if (field.label === "createdAt" || field.label === "updatedAt") {
              const timestamp = data[field.label];
              field.content = timestamp;
            } else {
              if (data[field.question] !== undefined) {
                field.content = data[field.question];
              } else if (data[field.label] !== undefined) {
                field.content = data[field.label];
              } else {
                field.content = "";
              }
            }
          });
          console.log("vuexでcommitしました", "@fetchFormContent");
          commit("setFormData", formTemplate);
        }
      }
    },
    formatFormNumber({ state, commit }, { label, useDate }) {
      const targetItemIndex = state.formData.findIndex(
        (item) => item.label === label
      );
      console.log("FORMATTING NUMBER@tabFilterFormTemplate");
      if (
        targetItemIndex !== -1 &&
        state.formData[targetItemIndex].inputType === "number" &&
        state.formData[targetItemIndex].content < 100
      ) {
        const formattedNumber = String(
          state.formData[targetItemIndex].content
        ).padStart(2, "0");
        let formattedContent = formattedNumber;
        console.log("useDate", useDate);
        if (useDate) {
          const today = new Date();
          const year = String(today.getFullYear()).padStart(4, "0");
          const month = String(today.getMonth() + 1).padStart(2, "0");
          const day = String(today.getDate()).padStart(2, "0");
          formattedContent = `${year}${month}${day}${formattedNumber}`;
        }
        console.log(formattedContent);
        // Dispatch the mutation
        commit("updateFormField", {
          label: label,
          content: Number(formattedContent),
        });
      }
    },
    async addData({ state, dispatch }, fields) {
      const data = fields.reduce((result, field) => {
        const value =
          field.inputType === "number"
            ? parseFloat(field.content)
            : field.inputType === "file"
            ? this.urls
            : field.content;

        if (
          value !== null &&
          value !== undefined &&
          !(typeof value === "number" && isNaN(value))
        ) {
          result[field.label] = value;
        }
        return result;
      }, {});
      if (state.formBeforeSubmit) {
        console.log(
          "before ///////",
          state.formBeforeSubmit
            ? state.formBeforeSubmit
            : "NULLなので脱出すべき"
        );
        await dispatch("updateFieldsFromDocument", state.formBeforeSubmit);
      } else {
        console.log("beforeSubmitはない");
      }
      // Add createdAt and updatedAt timestamps
      const timestamp = state.date;
      data.createdAt = timestamp;
      data.updatedAt = timestamp;
      console.log("data;   ;", data);
      const collectionRef = collection(
        db,
        state.formQueryFirebase.collectionName,
        state.formQueryFirebase.userDoc,
        state.formQueryFirebase.subCollection
      );
      try {
        let docRef = await addDoc(collectionRef, data);
        return docRef;
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },
    async updateData({ state, dispatch }, { fields, id }) {
      const data = fields.reduce((result, field) => {
        const value =
          field.inputType === "number"
            ? parseFloat(field.content)
            : field.inputType === "file"
            ? this.urls
            : field.content;

        if (
          value !== null &&
          value !== undefined &&
          !(typeof value === "number" && isNaN(value))
        ) {
          result[field.label] = value;
        }

        return result;
      }, {});
      if (state.formBeforeSubmit) {
        console.log("before ///////", state.formBeforeSubmit);
        await dispatch("updateFieldsFromDocument", state.formBeforeSubmit);
      } else {
        console.log("beforeSubmitはない");
      }
      const timestamp = state.date;
      data.updatedAt = timestamp;
      console.log("data;   ;", data);
      console.log("Queryyyyyy:", state.formQueryFirebase.subCollection, id);
      const docRef = doc(
        db,
        state.formQueryFirebase.collectionName,
        state.formQueryFirebase.userDoc,
        state.formQueryFirebase.subCollection,
        id
      );
      try {
        await updateDoc(docRef, data);
        return docRef;
      } catch (e) {
        console.error("Error updating document: ", e);
      }
    },

    async deleteData({ state }, id) {
      const docRef = doc(
        db,
        state.formQueryFirebase.collectionName,
        state.formQueryFirebase.userDoc,
        state.formQueryFirebase.subCollection,
        id
      );
      try {
        await deleteDoc(docRef);
      } catch (e) {
        console.error("Error deleting document: ", e);
      }
    },
    async updateFieldsFromDocument({ state }, beforeSubmit) {
      // Find the searchKey field content
      console.log("updating before submitting....", beforeSubmit);
      if (Object.keys(beforeSubmit).length === 0 || beforeSubmit === null) {
        console.log("before submit はありませんでした");
        return;
      }
      console.log("beforeSubmit in VUEX", beforeSubmit);
      let searchFieldValue = state.formData.find(
        (field) => field.label === beforeSubmit.searchKey
      ).content;
      console.log(beforeSubmit.searchKey, "=before=", searchFieldValue);
      // Get the document from the specified collection with the matching docField
      const q = query(
        collection(
          db,
          state.formQueryFirebase.collectionName,
          state.formQueryFirebase.userDoc,
          beforeSubmit.collectionName
        ),
        where(beforeSubmit.searchKey, "==", searchFieldValue)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return;
      }

      // Assuming there's only one document matching the query
      const docSnapshot = querySnapshot.docs[0];

      // Assign the retrieved values to the fields
      beforeSubmit.updateFieldLabels.forEach((fieldLabel) => {
        let fieldValue = docSnapshot.data()[fieldLabel];
        state.formData.find((field) => field.label === fieldLabel).content =
          fieldValue;
      });
    },
    async fetchListData({ state, commit }, payload) {
      commit("setListLoadingStatus", true);
      try {
        let {
          collectionName,
          userDoc: docId,
          subCollection: subCollectionName,
          filter,
          lastKey, // 変更: 最後のキー
          orderByField, // 追加: オーダーに使用するフィールド
          isLoadMore = false, // 追加: ロードモアフラグ
        } = payload;
        console.log(
          "listLastKey",
          state.listLastKey,
          "listKey is:",
          state.listKey
        );
        lastKey = state.listLastKey ? state.listLastKey : null;
        orderByField = state.listKey ? state.listKey : "facilityID";
        // targetCollectionの設定
        let targetCollection =
          subCollectionName && docId
            ? collection(db, collectionName, docId, subCollectionName)
            : collection(db, collectionName);

        // クエリの設定
        let q = lastKey
          ? query(
              targetCollection,
              orderBy(orderByField, "desc"),
              where(orderByField, "<", lastKey),
              limit(50)
            )
          : query(targetCollection, orderBy(orderByField, "desc"), limit(15));
        console.log(
          subCollectionName,
          lastKey,
          orderByField,
          "this is flexible variables by page"
        );
        // UIフィルタリング条件がある場合の適用
        if (filter && filter.field && filter.operator && filter.value) {
          q = query(q, where(filter.field, filter.operator, filter.value));
        }

        const listDataSnapshot = await getDocs(q);
        const listData = listDataSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // 最後のキーを更新
        let msg = listDataSnapshot.docs.length + "件のアイテムを読み込みました";

        commit("setListLoadMsg", msg);
        const lastDocument =
          listDataSnapshot.docs[listDataSnapshot.docs.length - 1];
        if (lastDocument) {
          const lastDocumentKey = lastDocument.data()[orderByField];
          if (isLoadMore) {
            console.log("データに追加");
            commit("appendListData", listData); // データに追加
          } else {
            console.log("データを置換", listData);
            commit("setListData", listData); // データを置き換え
          }
          commit("setListLastKey", lastDocumentKey); // 追加: 最後のキーを保存
          console.log("Last Key is :", lastDocumentKey);
        } else {
          if (!isLoadMore) {
            commit("setListData", listData); // データを置き換え
          }
        }
      } catch (error) {
        console.error("Error fetching list data:", error);
        commit("setError", error.message);
      } finally {
        commit("setListLoadingStatus", false);
      }
    },
    async updateFilterData({ state, commit }) {
      console.log("<2> UPDATING FILTER DATA...");
      for (let obj of state.loadCollection) {
        let { subCollection, listName, listKey, labelKey } = obj;
        labelKey === undefined ? (labelKey = listKey) : labelKey;

        try {
          if (subCollection === "/") {
            const docSnap = await getDoc(
              doc(
                db,
                state.formQueryFirebase.collectionName,
                state.formQueryFirebase.userDoc
              )
            );
            const data = docSnap.data()[listKey];
            console.log("UPDATE", subCollection);
            commit("updateFilterData", {
              label: labelKey || listName,
              options: [data],
              content: [data],
            });
          } else {
            const collectionRef = collection(
              db,
              state.formQueryFirebase.collectionName,
              state.formQueryFirebase.userDoc,
              subCollection
            );
            const dataSnap = await getDocs(collectionRef);
            const data = dataSnap.docs.map((doc) => doc.data()[listKey]);
            console.log("UPDATE", subCollection);
            commit("updateFilterData", {
              label: labelKey || listName,
              options: data,
              content: data,
            });
          }
        } catch (error) {
          console.error(`Error getting ${subCollection} data: `, error);
        }
      }
    },
    sortItems({ state }) {
      console.log("<3> sort items...", state.listSortBy);
      state.listData.sort((a, b) => {
        let aValue = a[state.listSortBy];
        let bValue = b[state.listSortBy];

        // If it's "ー" or empty, set to Infinity or -Infinity for sorting based on sortOrder
        if (aValue === "ー" || !aValue)
          aValue = state.listSortOrder === "大きい順" ? -Infinity : Infinity;
        if (bValue === "ー" || !bValue)
          bValue = state.listSortOrder === "大きい順" ? -Infinity : Infinity;

        // Check if it's a datetime-local format
        if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(aValue)) {
          aValue = new Date(aValue).getTime();
        }
        if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(bValue)) {
          bValue = new Date(bValue).getTime();
        }

        // If it's date format
        else if (/^\d{4}-\d{2}-\d{2}$/.test(aValue)) {
          aValue = new Date(aValue).getTime();
        }
        if (/^\d{4}-\d{2}-\d{2}$/.test(bValue)) {
          bValue = new Date(bValue).getTime();
        }

        // Check for numbers
        else if (!isNaN(Number(aValue))) {
          aValue = Number(aValue);
        }
        if (!isNaN(Number(bValue))) {
          bValue = Number(bValue);
        }

        // Sorting logic
        if (state.listSortOrder === "大きい順") {
          return aValue < bValue ? 1 : -1;
        } else {
          return aValue > bValue ? 1 : -1;
        }
      });
    },
    sortException({ commit, state }) {
      if (state.listException === "tenDegit") {
        // 元のデータの順序を保存するため、indexとともに保存します。
        const indexedListData = state.listData.map((item, index) => ({
          index,
          ...item,
          facilityIDString: item.facilityID
            ? item.facilityID.toString()
            : index, // 数値を文字列に変換
        }));

        // 10桁でないfacilityIDを持つ項目と10桁の項目を分けます。
        const exceptions = indexedListData.filter(
          (item) => item.facilityIDString.length !== 10
        );
        const standard = indexedListData.filter(
          (item) => item.facilityIDString.length === 10
        );

        // 10桁の項目は元の順序を保つため、indexでソートします。
        standard.sort((a, b) => a.index - b.index);

        // 最終的な配列は例外項目が最上位になり、その後に標準項目が続きます。
        const sortedItems = [...exceptions, ...standard];

        // 結合したリストでstateを更新します。
        commit(
          "setListData",
          sortedItems.map((item) => {
            // indexと追加したfacilityIDStringプロパティを削除して、元のデータ構造に戻します。
            const { ...originalItem } = item;
            return originalItem;
          })
        );
      }
    },
  },
  getters: {
    filterItemsByData: () => (items, filterData, displayMapping) => {
      let processedItems = items;

      // Filter items if filterData exists
      if (filterData && filterData.content !== null) {
        if (
          filterData.inputType === "range" ||
          filterData.inputType === "range-date"
        ) {
          processedItems = items.filter((item) => {
            return (
              item[filterData.label] >=
                (filterData.content["min"] || -1000000000) &&
              item[filterData.label] <=
                (filterData.content["max"] || 1000000000)
            );
          });
        } else if (filterData.inputType === "checkbox") {
          processedItems = items.filter((item) =>
            filterData.content.includes(item[filterData.label])
          );
        }
      }

      // Formatting items with displayMapping
      return processedItems.map((data) => {
        const item = {
          id: data.id,
        };
        for (const key in displayMapping) {
          const {
            key: originalKey,
            formatFunction,
            unit,
          } = displayMapping[key];
          const value = data[originalKey];
          const formattedValue = formatFunction ? formatFunction(value) : value;
          item[key] = {
            value:
              value !== undefined && value !== null ? formattedValue : "ー",
            unit: unit ? unit : "",
          };
        }
        return item;
      });
    },
  },
});
export default store;
