<template>
  <div class="menu">
    <div class="menu-top">
      <nav :class="{ bottom: isMobile }">
        <h1 v-if="!isMobile">
          <router-link :to="'/'" class="title-link"
            ><span
              ><img src="/assets/raku-sika.png" alt="ラクシカのロゴ" /></span
            ><span>ラク</span><span>シカ</span></router-link
          >
        </h1>
        <ul class="sidebar-links">
          <li
            v-for="(link, index) in filteredLinks"
            @click="goPage(link)"
            :key="index"
            class="sidebar-link"
            :class="{ 'selected-link': currentRouteIncludes(link.path) }"
          >
            <router-link class="sidebar-link-btn" :to="link.path">
              <span class="icon">
                {{ link.icon }}
              </span>
              <span class="sidebar-link-text">{{ link.label }}</span>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
    <div class="menu-bottom">
      <router-link v-if="userPlan === 'アドミン'" :to="'/admin-backup'"
        >管理者画面へ</router-link
      >
      <p>{{ userId }}</p>
    </div>
  </div>
</template>
<script>
import { auth } from "@/firebase.js";

export default {
  name: "CommonSidebar",
  data() {
    return {
      isLoggedIn: false,
      user: null,
      isMobile: false,
      links: [
        {
          label: "設定",
          path: "/setting",
          icon: "⚙",
          auth: true,
          category: "detail",
        },
      ],
    };
  },
  computed: {
    dynamicLinks() {
      if (
        this.$store.state.userRoutes &&
        this.$store.state.userRoutes.sidebarRoutes
      ) {
        return this.$store.state.userRoutes.sidebarRoutes;
      }
      return this.links;
    },
    filteredLinks() {
      return this.dynamicLinks
        .filter((link) => {
          // ここでのフィルタリングロジックを適切に調整します
          if (link.category === "product") {
            return this.userPlan === "スタンダード";
          } else if (
            link.category === "individual" ||
            link.category === "detail"
          ) {
            return true;
          } else {
            return (
              (this.isLoggedIn && link.auth) || (!this.isLoggedIn && !link.auth)
            );
          }
        })
        .sort((a, b) => a.index - b.index);
    },
    userPlan() {
      return this.$store.state.userPlan;
    },
  },
  props: {
    userId: String,
  },
  methods: {
    currentRouteIncludes(path) {
      const modifiedPath = path.replace("-list", "");
      return this.$route.path.includes(modifiedPath);
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth < 768;
    },
    goPage(item) {
      this.$router.push({ path: item.path });
    },
  },
  mounted() {
    auth.onAuthStateChanged((user) => {
      this.isLoggedIn = !!user;
    });
    this.checkIsMobile();
    window.addEventListener("resize", this.checkIsMobile);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<style scoped>
.menu {
  position: fixed;
  z-index: 5;
  width: 15%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100vh;
  background: #eef;
  overflow-y: scroll;
  justify-content: space-between;
}
.menu h1 {
  padding: 1.5rem 0.5rem 0.5rem;
  margin: 0;
  font-size: 1rem;
}
.menu h1 span {
  display: flex;
}
.menu h1 .title-link {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.menu h1 img {
  max-width: 1.15rem;
  margin: 0.2rem;
}
.menu .menu-bottom {
  font-size: 0.5rem;
  margin: 1rem auto;
  overflow-x: scroll;
  text-align: center;
}
.menu .sidebar-links {
  position: static;
  display: flex;
  list-style: none;
  flex-direction: column;
  text-align: left;
  padding: 0;
  margin: 0;
  font-weight: 600;
}
.menu .sidebar-link {
  margin: 0;
  padding: 2rem 1rem;
  cursor: pointer;
  font-size: 1rem;
}
.menu .sidebar-link:hover {
  background: #ddf;
}
.menu .icon {
  margin-right: 0.5rem;
}
.selected-link {
  background-color: #ddf;
}

@media (max-width: 768px) {
  .menu {
    bottom: 0;
    width: 100%;
    bottom: 0;
    width: 100vw;
    height: auto;
    z-index: 3;
  }
  .menu .menu-bottom {
    display: none;
  }
  .menu .sidebar-links {
    flex-direction: row;
    width: 100%;
    padding: 0;
    margin: 0;
    min-height: 5rem;
    overflow: scroll;
    background: rgba(200, 200, 250, 0);
  }
  .menu .sidebar-link {
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: center;
    padding: 0;
    background: rgba(200, 200, 250, 0.2);
    backdrop-filter: blur(3px);
    border-radius: 1rem 1rem 0 0;
  }
  .menu .sidebar-link-btn {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: inherit;
    padding: 0;
  }
  .menu .sidebar-link .icon {
    display: inline-block;
    text-align: center;
    align-items: center;
    font-size: 1.2rem;
  }
  .sidebar-link-text {
    display: none;
  }
  .menu .sidebar-link:hover {
    background: rgba(200, 200, 255, 0.5);
  }
  .selected-link {
    background: rgba(200, 200, 255, 0.5);
  }
}
</style>
